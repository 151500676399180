<template>
  <div>
    <div class="mt-4">
      <svg-active-tasks-icon class="p-0" />
      <h1 class="ml-4 mb-0" v-if="tasks">
        Aktívne úlohy ({{ tasks[0].length }})
      </h1>
    </div>
    <GroupTable
      v-if="tasks"
      class="--clickable-rows"
      :tableItems="tasks[0]"
      :tableFields="isAdmin ? adminActiveTaskFields : memberActiveTaskFields"
      @rowClicked="navigateToTask"
    />
    <div class="mt-5">
      <svg-inactive-tasks-icon />
      <h1 class="ml-4 p-0" v-if="tasks">
        História úloh ({{ tasks[1].length }})
      </h1>
    </div>
    <GroupTable
      v-if="tasks"
      class="inactive-tasks --clickable-rows"
      :tableItems="tasks[1]"
      :tableFields="
        isAdmin ? adminInactiveTaskFields : memberInactiveTaskFields
      "
      @itemToReactivate="reactivateTask"
      @groupToRemove="$refs['remove-task-modal'].showModal($event)"
      @rowClicked="navigateToTask"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { partition, set } from "lodash";
import { mapGetters } from "vuex";
import axios from "/utils/axios";
import * as Sentry from "@sentry/vue";

const api = {
  admin: {
    getTasks: (groupId) => {
      return axios.getAuth("tasks", {
        params: {
          populate: ["course.thumbnail"],
          sort: ["startsAt:desc"],
          filters: {
            group: {
              id: groupId,
            },
          },
        },
      });
    },
    reactivateTask: (taskId, newDeadline) => {
      return axios.putAuth(`tasks/${taskId}`, {
        data: {
          deadline: newDeadline,
        },
      });
    },
    removeTask: (taskId) => {
      return axios.deleteAuth(`tasks/${taskId}`);
    },
  },
  member: {
    getTasks: (groupId, userId) => {
      return axios.getAuth("tasks", {
        params: {
          populate: {
            course: { populate: ["thumbnail", "quiz.questions.answers"] },
            responses: set({}, "filters.groupMember.user.id.$eq", userId),
          },
          sort: ["startsAt:desc"],
          filters: {
            group: {
              id: groupId,
            },
          },
        },
      });
    },
  },
};

export default {
  components: {
    GroupTable: () => import("/components/Group/GroupTable.vue"),
    "svg-active-tasks-icon": () =>
      import("/assets/icons/activeTasks.svg?inline"),
    "svg-inactive-tasks-icon": () =>
      import("/assets/icons/inactiveTasks.svg?inline"),
  },

  data() {
    return {
      adminActiveTaskFields: [
        { key: "courseImg", label: "Kurz" },
        { key: "course", label: "" },
        { key: "starts_at", label: "Termín zadania úlohy" },
        { key: "deadline", label: "Termín splnenia úlohy" },
        { key: "watch_or_reactivate", label: "" },
      ],
      adminInactiveTaskFields: [
        { key: "courseImg", label: "Kurz" },
        { key: "course", label: "", class: "white" },
        { key: "starts_at", label: "Termín zadania úlohy", class: "white" },
        { key: "deadline", label: "Termín splnenia úlohy", class: "white" },
        { key: "watch_or_reactivate", label: "" },
        // {key: 'remove', label: '', class: 'white'}
      ],
      memberActiveTaskFields: [
        { key: "courseImg", label: "Kurz" },
        { key: "course", label: "" },
        { key: "starts_at", label: "Termín zadania úlohy" },
        { key: "deadline", label: "Termín splnenia úlohy" },
        { key: "exams_count", label: "Počet bodov" },
        // {key: 'go_to_course', label: ''},
      ],
      memberInactiveTaskFields: [
        { key: "courseImg", label: "Kurz" },
        { key: "course", label: "" },
        { key: "starts_at", label: "Termín zadania úlohy" },
        { key: "deadline", label: "Termín splnenia úlohy" },
        { key: "exams_count", label: "Počet bodov" },
        { key: "space", label: "" },
      ],
      tasks: null,
      modalConfig: {
        adminHeaderText: "Odstrániť úlohu",
        adminBodyText: "Ste si istý, že chcete odstraniť túto úlohu",
      },
    };
  },

  async created() {
    await this._loadTasks();
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
  },

  methods: {
    async reactivateTask(tid) {
      this.$toast.clear();

      try {
        await api.admin.reactivateTask(
          tid,
          moment().add(7, "days").format("YYYY-MM-DD HH:mm:ss").toString()
        );

        this.$toast.success("Úloha bola úspešne reaktivovaná");
        this.$nextTick(async () => {
          await this._loadTasks();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async removeTask(tid) {
      this.$toast.clear();

      try {
        await api.admin.removeTask(tid.id);

        this.$toast.success("Úloha bola úspešne odstránená");
        this.$nextTick(async () => {
          await this._loadTasks();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async navigateToTask(item) {
      this.$router.push(`${this.$route.path}/uloha/${item.id}`);
    },
    async _loadTasks() {
      const tasks = this.isAdmin
        ? await api.admin.getTasks(this.$route.params.id)
        : await api.member.getTasks(this.$route.params.id, this.user.id);

      const currentDate = moment();

      this.tasks = partition(tasks.data, (task) => {
        const started = currentDate.isSameOrAfter(task.attributes.startsAt);
        const active = currentDate.isBefore(task.attributes.deadline);

        return !started || (started && active);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 20px;
  display: inline-block;
  color: #2b2e4a;
}

::v-deep .white {
  color: white !important;
}

.inactive-tasks {
  color: white !important;
  background-color: #0064ff;

  &::v-deep p {
    color: white !important;
  }
}

.inactive-tasks ::v-deep table tr th {
  background-color: white;
}
</style>
